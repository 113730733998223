import './index.css';
import App from './App.js';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.querySelector('#ayea-player'));

const productId = document.getElementById('ayea-product-id').value;

root.render(
    <App  productId={productId} />
);
